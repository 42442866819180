import { Box, Text, Image, Center } from '@chakra-ui/react';

import { CustomerButton } from '@/components/CustomerButton';

function FourOhFour() {
  return (
    <Box>
      <Center display="flex" flexDirection="column">
        <Image src="/404-graphic.png" maxHeight="60vh" maxWidth="80%" />
        <Text my={5}>This page could not be found.</Text>
        <a href="\">
          <CustomerButton title="Go Back Home" />
        </a>
      </Center>
    </Box>
  );
}

export default FourOhFour;
